.Header{
    height: 50px;
    color: white;
    /* background-color: #E03100; */
    background-image: linear-gradient(to right, #E03100, #611500);

}

.Header .Logo {
  margin-top: -15px;
  height: 40px;
}

.Header .Icon{
  margin-right: 5px;
}

.navbar-dark .navbar-nav .nav-link {
    color: white;
}

.navbar-light .navbar-nav .nav-link {
  color: white;
  font-size: 18px;
  padding-left: 30px;
}

.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link{
  border-bottom: 1px white solid;
}

.navbar-light .navbar-brand {
  color: white;
}