.Banner {

}

.Banner .title {
  padding: 10px;
  color: #FF4500;
  font-size: 20px;
}

.Banner .subtitle{
  font-size: 30px;
  font-style: italic;
}

