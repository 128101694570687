.Bio {
  box-shadow: 10px 5px 5px #FF4500;
  border: 1px solid #FF4500;
}

.Bio img{
  width: 100%;
  /* margin: 0px; */
  padding-right: 0px;
  /* margin-left: 10px; */
}

.Bio .PhotoContainer {
border: 1px solid #FF4500;
width: 100%;
}

.Bio .CardTitle {
  color: #FF4500;
}