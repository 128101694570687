.ProjectCard {
  margin-left: 20px;
}

.ProjectCard .CardImage{
  /* max-height: 80px; */
}

.ProjectCard .Button1 {
  display: inline-block;
  line-height: 2.2rem;
  color: #fff;
  background-color: #E03100;
  border-color: #E03100;
  width: 100%;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  border-radius: 0.25rem;
}