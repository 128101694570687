.Layout {
  /* height: 150vh;
  background-color: beige;
  background-image: linear-gradient(
      to right bottom,
      rgba(255, 253, 253, 0.9),
      rgba(215, 51, 6, 0.9)
    ),
    url("../../assets/images/paulofreire.jpg");
  background-size: cover; 
  background-position: top;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  z-index: -10; */
}

.Layout .title {
  margin: auto;
}

.Layout .Content {
  margin: 2rem;

}