.Footer{
    margin: 0;
    height: 50px;
    color: white;
    /* background-color: #E03100; */
    background-image: linear-gradient(to right, #E03100, #611500);
    display: flex;
    justify-content: center;

}

.Footer .Icon {
    padding: 5px;
}
